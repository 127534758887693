/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import Prando from 'prando';

import Game from './Game';
import theme from './theme';
import data from './queens';
import { filtersFromState, marshallState } from './state';

const rng = new Prando();
function shuffle<T>(array: Array<T>): Array<T> {
  let m = array.length;

  while (m) {
    const i = rng.nextInt(0, m--);

    const t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

const { hasState, selectableQueens, activeQueens } = filtersFromState(window.location.pathname.substr(1));

function App() {
  const selectQueens = () => shuffle(data.filter(activeQueens)).slice(0, 21);
  const [queens, setQueens] = useState(selectQueens());

  const availableQueens = queens.filter(selectableQueens);
  const pickQueen = () => availableQueens[Math.floor(Math.random()*availableQueens.length)];
  const player = pickQueen();
  

  const prepGame = () => {
    // If we have a path...we should clear it when we go to make a new game...to avoid confusion.
    if (hasState) {
      window.location.pathname = '/';
      return;
    }

    setQueens(selectQueens());
  };

  const gameState = marshallState(queens, player);

  return (
    <ThemeProvider theme={theme}>
      <Game
        queens={queens}
        player={player}
        gameState={gameState}
        onNewGame={prepGame}
      />
    </ThemeProvider>
  );
}

export default App;
