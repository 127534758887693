/** @jsxImportSource @emotion/react */
import React from 'react';

interface Props {
    name: string;
    src: string;
    disabled?: boolean;
    onToggle?: VoidFunction;
    className?: string;
    horizontal?: boolean;
}

export const Person = ({name, src, className, disabled, onToggle, horizontal}: Props) => (
  <div className={className} onClick={onToggle} css={{
    width: (horizontal ? undefined : 200),
    background: 'white',
    borderRadius: '5px',
    boxShadow: '#bbb 2px 2px 5px',
    filter: `grayscale(${disabled ? '100%' : '0%'}) brightness(${disabled ? '0.6' : '1'})`,
    overflow: 'hidden',
    display: 'grid',
    gridAutoFlow: (horizontal ? 'column' : 'initial'),
    alignItems: (horizontal ? 'center' : 'start'),
    justifyContent: (horizontal ? 'left' : 'space-between'),
  }}>
    <img alt={name} src={src} css={{
      height: (horizontal ? 100 : undefined),
      margin:0,
    }} />
    <h3 css={{
      textAlign: (horizontal ? 'inherit' : 'center'),
      margin: 0,
      padding: 10,
      textDecoration: (disabled ? 'line-through' : 'none'),
    }}>{name}</h3>
  </div>
);

export default Person;
