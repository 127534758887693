/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Board from './Board';
import Person from './Person';
import Button from './Button';

interface Queen {
  id: number,
  name: string,
  image_url: string,
  quote: string,
}

interface Props {
  queens: Array<Queen>,
  player: Queen,
  gameState: string,
  onNewGame: VoidFunction,
}

export const Game = ({queens, player, gameState, onNewGame}: Props) => {
  const [marked, setMarked] = useState(new Set<number>()); // Queen's IDs

  const newGame = () => {
    setMarked(new Set());
    onNewGame();
  };

  return (
    <div css={{
      display: 'grid',
      gridTemplateColumns: '200px 5fr',
      gridTemplateRows: '1fr',
      gap: '1em',
      maxWidth: '1276px',
      margin: '0 auto',
    }}>
      <div css={{margin: 0}}>
        <h2 css={{margin: '0 0 10px'}}>
          Your queen
        </h2>
        <Person

          name={player.name}
          src={player.image_url}
          />
        {/* <p css={{
          margin: 5,
          color: '#666',
          fontStyle: 'italic',
        }}>
          "{player.quote}"
        </p> */}
        <Button onClick={newGame} css={{margin: '10px 0'}}>New Game</Button>

        Challenge your friend by sending them this link:
        <input
          type='textfield'
          value={`${window.location.protocol}//${window.location.host}/${gameState}`}
          disabled
        />
      </div>
      <Board>
        {queens.filter(q => player.id !== q.id).map(q => <Person
          key={q.id}
          name={q.name}
          src={q.image_url}
          disabled={marked.has(q.id)}
          onToggle={() => {
            if (marked.has(q.id)) {
              marked.delete(q.id);
            } else {
              marked.add(q.id);
            }

            // We do this a kinda strange way to deal with React's desire to not re-render, since the object identity won't change otherwise.
            setMarked(new Set(marked));
          }}
          css={{margin: '0 5px 10px'}}
          />)}
      </Board>
    </div>  
  );
};

export default Game;
