import styled from '@emotion/styled';

export const Button = styled.button`
    padding: 5px;
    background-color: lemonchiffon;
    font-size: 24px;
    border: 0;
    border-radius: 3px;
    width: 100%;
    box-shadow: #bbb 2px 2px 5px;
    :focus {
        outline: none;
    }
`;

export default Button;
