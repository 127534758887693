
interface Queen {
    id: number;
}

interface StateObject {
    queens: Array<number>,
    existingPlayer: number,
}

export function marshallState(activeQueens: Array<Queen>, selectedQueen: Queen): string {
  const stateObj: StateObject = {
    queens: activeQueens.map(q => q.id),
    existingPlayer: selectedQueen.id,
  };

  return btoa(JSON.stringify(stateObj));
}

type QueenFilter = (arg0: Queen) => boolean;

interface Filters {
    hasState: boolean,
    // This is the set of queens that are acceptable for picking for the local player to be.
    selectableQueens: QueenFilter,
    // This is the universe of queens that exist in this game.
    activeQueens: QueenFilter,
}

export function filtersFromState(state: string): Filters {
  // If there is no state data, then we have nothing to start us off with.
  if (state.length === 0) {
    return {
        hasState: false,
        selectableQueens: _ => true,
        activeQueens: _ => true,
    }
  }

  // Parse what we know and build filtering functions that can use the complete data set.
  const stateObj = JSON.parse(atob(state)) as StateObject;
  const inGameQueenSet = new Set(stateObj.queens);
  return {
      hasState: true,
      selectableQueens: (q: Queen) => q.id !== stateObj.existingPlayer,
      activeQueens: (q: Queen) => inGameQueenSet.has(q.id),
  }
};
