/** @jsxImportSource @emotion/react */
import React from 'react';

export const Board = (props: {children?: React.ReactNode}) => (
  <div css={{
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  }}>
    {props.children}
  </div>  
);

export default Board;
