export interface Queen {
  id: number;
  name: string;
  winner: boolean;
  missCongeniality: boolean;
  image_url: string;
  quote: string;
}

export const queens: Array<Queen> = [
  {
    id: 1,
    name: "Victoria 'Porkchop' Parker",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/victoria-porkchop-parker.jpg",
    quote: "Would you fuck me? I'd fuck me.",
    allowed: false,
  },
  {
    id: 2,
    name: "Tammie Brown",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/tammie-brown.png",
    quote: "I'm not a slut, I'm a lady. You look under my skirt, and it will stick you in the eye though.",
    allowed: true,
  },
  {
    id: 3,
    name: "Akashia",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/akashia.jpg",
    quote: "If I was a girl, I'd be a stripper, or a slut pregnant with a whole bunch of children.",
    allowed: false,
  },
  {
    id: 4,
    name: "Jade",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jade.jpg",
    quote: "Jade is definitely my alter-ego, basically she has more balls than I do!",
    allowed: false,
  },
  {
    id: 5,
    name: "Ongina",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/ongina.jpg",
    quote: "If I don't win this, I swear to god I'm gonna cut someone!",
    allowed: false,
  },
  {
    id: 9,
    name: "Shannel",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/shannel.jpg",
    quote: "I am amazed at myself; In my mind... I've already won.",
    allowed: false,
  },
  {
    id: 10,
    name: "Rebecca Glasscock",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/rebecca-glasscock.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 11,
    name: "Nina Flowers",
    winner: false,
    missCongeniality: true,
    image_url: "http://www.nokeynoshade.party/images/nina-flowers.jpg",
    quote: "I don't consider myself as a female impersonator, I like to be more androgynous. It's an extravaganza.",
    allowed: false,
  },
  {
    id: 12,
    name: "Bebe Zahara Benet",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/bebe-zahara-benet.jpg",
    quote: "I bring a lot of international influence, and right now I really feel like I'm ahead of the game.",
    allowed: false,
  },
  {
    id: 13,
    name: "Shangela Laquifa Wadley",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/shangela-laquifa-wadley.png",
    quote: "Halleloo!",
    allowed: true,
  },
  {
    id: 14,
    name: "Nicole Paige Brooks",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/nicole-paige-brooks.jpg",
    quote: "I'm Nicole Paige Brooks, from ATLANTA, GEORGIA!",
    allowed: false,
  },
  {
    id: 15,
    name: "Mystique Summers Madison",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/mystique-summers-madison.jpg",
    quote: "Bitch I am from Chicago!",
    allowed: false,
  },
  {
    id: 16,
    name: "Sonique",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/sonique.jpg",
    quote: "My time is valuable too, so hit it!",
    allowed: false,
  },
  {
    id: 17,
    name: "Morgan McMichaels",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/morgan-mcmichaels.jpg",
    quote: "People think that, since I'm pretty, and I'm sexy, that I should just do slutty all the time.",
    allowed: false,
  },
  {
    id: 18,
    name: "Sahara Davenport",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/sahara-davenport.jpg",
    quote: "I'm happy I survived, but of course I'm sad, you know I sent my friend home.",
    allowed: false,
  },
  {
    id: 19,
    name: "Jessica Wild",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jessica-wild.jpg",
    quote: "My biggest fear in the competition is, with my English, but if I don't understand something I am going to smile..",
    allowed: false,
  },
  {
    id: 20,
    name: "Pandora Boxx",
    winner: false,
    missCongeniality: true,
    image_url: "http://www.nokeynoshade.party/images/pandora-boxx.jpg",
    quote: "I'm Pandora Boxx and I'm thirty-bleep years old.",
    allowed: false,
  },
  {
    id: 21,
    name: "Tatianna",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/tatianna.jpg",
    quote: "Cause baby boy what you see, isnt always the truth.",
    allowed: false,
  },
  {
    id: 22,
    name: "Jujubee",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/jujubee.png",
    quote: "Jujubee's just this fabulous Asian girl, she's very like poppy and like, fashion-forward. She loves accessories and she likes glitter, 'cause it's wicked sparkly!",
    allowed: true,
  },
  {
    id: 23,
    name: "Raven",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/raven.jpg",
    quote: "I like to be that mysterious, dark, ice queen. I think I have a look that people might be a little intimidated by.",
    allowed: false,
  },
  {
    id: 24,
    name: "Tyra Sanchez",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/tyra-sanchez.jpg",
    quote: "I'm not a bitch. I'm America's Sweetheart.",
    allowed: false,
  },
  {
    id: 25,
    name: "Venus D-Lite",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/venus-d-lite.jpg",
    quote: "You can be my friend or you can be my enemy, just say it to my face.",
    allowed: false,
  },
  {
    id: 26,
    name: "Phoenix",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/phoenix.jpg",
    quote: "You can be my friend or you can be my enemy, just say it to my face.",
    allowed: false,
  },
  {
    id: 27,
    name: "Mimi Imfurst",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/mimi-imfurst.jpg",
    quote: "This time around, I will not pick nobody up, it is time for America to see who I really am.",
    allowed: false,
  },
  {
    id: 28,
    name: "India Ferrah",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/india-ferrah.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 29,
    name: "Mariah Balenciaga",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/mariah-balenciaga.jpg",
    quote: "My name is Mariah, aka Mariah Paris, aka Mariah Successful.",
    allowed: false,
  },
  {
    id: 30,
    name: "Stacey Lane Matthews",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/stacey-lane-matthews.jpg",
    quote: "I am definitely a country queen. I live on a dirt road surrounded by corn fields. Can't get more country than that.",
    allowed: false,
  },
  {
    id: 31,
    name: "Delta Work",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/delta-work.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 32,
    name: "Carmen Carrera",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/carmen-carrera.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 33,
    name: "Yara Sofia",
    winner: false,
    missCongeniality: true,
    image_url: "http://www.nokeynoshade.party/images/yara-sofia.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 34,
    name: "Alexis Mateo",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/alexis-mateo.jpg",
    quote: "I don't like when they label you as 'the latin queen'.",
    allowed: false,
  },
  {
    id: 35,
    name: "Manila Luzon",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/manila-luzon.png",
    quote: "Try something new -- do it with an Indian guy!",
    allowed: true,
  },
  {
    id: 36,
    name: "Raja",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/raja.jpg",
    quote: "They're a star fucker. You're a star. Let them fuck the star.",
    allowed: false,
  },
  {
    id: 37,
    name: "Alisa Summers",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/alisa-summers.jpg",
    quote: "I'm definitely a fishy queen, I can walk down the street and I can never get clocked! People look at me more so about how big my titties and ass are!",
    allowed: false,
  },
  {
    id: 38,
    name: "LaShauwn Beyond",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/lashauwn-beyond.jpg",
    quote: "I'm not here to make best buddies bitch! This is not RuPaul's Best Friend Race!",
    allowed: false,
  },
  {
    id: 39,
    name: "The Princess",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/the-princess.jpg",
    quote: "I'm the princess of drag.",
    allowed: false,
  },
  {
    id: 40,
    name: "Madam LaQueer",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/madam-laqueer.jpg",
    quote: "Are we talking about beavers or... EEEEWWWWWWW!",
    allowed: false,
  },
  {
    id: 41,
    name: "Milan",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/milan.jpg",
    quote: "Milan is FIERCE! She's FABULOUS, she's DIVA! She can act, sing, dance, produce, and you don't want it from her!",
    allowed: false,
  },
  {
    id: 42,
    name: "Jiggly Caliente",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jiggly-caliente.jpg",
    quote: "Jiggly is New York's plus size barbie, she's got swerve for your nerve, you can't take her!",
    allowed: false,
  },
  {
    id: 43,
    name: "Willam Belli",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/willam-belli.png",
    quote: "I'm not gonna RuPaulogize for anything that I'm doing now.",
    allowed: true,
  },
  {
    id: 44,
    name: "Dida Ritz",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/dida-ritz.png",
    quote: "I don't want her leaving saying that Drag Queen did a horrible job on my song.",
    allowed: false,
  },
  {
    id: 45,
    name: "Kenya Michaels",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kenya-michaels.jpg",
    quote: "I'm a little person, but I'm FIERCE... BITCH!",
    allowed: false,
  },
  {
    id: 46,
    name: "Latrice Royale",
    winner: false,
    missCongeniality: true,
    image_url: "/img/queens/latrice-royale.png",
    quote: "Jesus is a biscuit! Let him sop you up!",
    allowed: true,
  },
  {
    id: 47,
    name: "Phi Phi O'Hara",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/phi-phi-o'hara.png",
    quote: "You don't need RuPaul to be big. I can say for a fact that people knew who Phi Phi was before I got here. So I don't need RuPaul to say I'm sickening. I know I'm sickening. I don't need her to tell me that.",
    allowed: false,
  },
  {
    id: 48,
    name: "Chad Michaels",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/chad-michaels.png",
    quote: "Everything I've gone through in my life has been because of Cher... the people I've gotten to meet, the clothes on my back. I basically owe her everything.",
    allowed: true,
  },
  {
    id: 49,
    name: "Sharon Needles",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/sharon-needles.jpg",
    quote: "I look spooky, but I'm really nice!... At least for now!",
    allowed: false,
  },
  {
    id: 50,
    name: "Penny Tration",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/penny-tration.jpg",
    quote: "Hello boys!",
    allowed: false,
  },
  {
    id: 51,
    name: "Serena Cha Cha",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/serena-chacha.jpg",
    quote: "Does it bother you I keep saying I'm 21?!",
    allowed: false,
  },
  {
    id: 52,
    name: "Monica Beverly Hillz",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/monica-beverly-hillz.jpg",
    quote: "Hello girls",
    allowed: false,
  },
  {
    id: 53,
    name: "Vivienne Piney",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/vivienne-piney.jpg",
    quote: "Hey y'all!",
    allowed: false,
  },
  {
    id: 54,
    name: "Honey Mahogany",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/honey-mahogany.jpg",
    quote: "Hey sisters!",
    allowed: false,
  },
  {
    id: 55,
    name: "Lineysha Sparx",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/lineysha-sparkx.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 56,
    name: "Jade Jolie",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jade-jolie.jpg",
    quote: "Serving up fish, tuna on a platter.",
    allowed: false,
  },
  {
    id: 57,
    name: "Ivy Winters",
    winner: false,
    missCongeniality: true,
    image_url: "http://www.nokeynoshade.party/images/ivy-winters.jpg",
    quote: "Hey ladies! Oh my god!",
    allowed: false,
  },
  {
    id: 58,
    name: "Alyssa Edwards",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/alyssa-edwards.png",
    quote: "BACKROLLS?!?!",
    allowed: true,
  },
  {
    id: 59,
    name: "Coco Montrese",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/coco-montrese.jpg",
    quote: "I'm not joking bitch!",
    allowed: false,
  },
  {
    id: 60,
    name: "Detox Icunt",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/detox.jpg",
    quote: "Wow... Holy shit we're here",
    allowed: false,
  },
  {
    id: 61,
    name: "Roxxy Andrews",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/roxxy-andrews.jpg",
    quote: "When life pulls you down, put on a shiny sequence gown",
    allowed: false,
  },
  {
    id: 62,
    name: "Alaska Thunderfuck 5000",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/alaska-thunderfuck-5000.png",
    quote: "I'll send you 10,000 dollars via PayPal if you let me stay in this competition",
    allowed: true,
  },
  {
    id: 63,
    name: "Jinkx Monsoon",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jinkx-monsoon.jpg",
    quote: "The name comes from Jinx, like you owe me a coke and Monsoon like the natural disaster because if there's a one in a million chance that something will go awry it's most likely going to happen to Jinkx, of course...",
    allowed: false,
  },
  {
    id: 64,
    name: "Kelly Mantle",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kelly-mantle.jpg",
    quote: "Don't wear bacon!",
    allowed: false,
  },
  {
    id: 65,
    name: "Magnolia Crawford",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/magnolia-crawford.jpg",
    quote: "I view myself as the world's most glamorous trash queen.",
    allowed: false,
  },
  {
    id: 66,
    name: "Vivacious",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/vivacious.jpg",
    quote: "Liza Minelli lies.",
    allowed: false,
  },
  {
    id: 70,
    name: "April Carrion",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/april-carrion.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 71,
    name: "Gia Gunn",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/gia-gunn.jpg",
    quote: "Just got off the boat, you know, little trip from Asia, just landed like fresh tilapia",
    allowed: false,
  },
  {
    id: 72,
    name: "Milk",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/milk.jpg",
    quote: "Milk does the body good, gurl.",
    allowed: false,
  },
  {
    id: 73,
    name: "Laganja Estranja",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/laganja-estranga.jpg",
    quote: "Well I'm young and I'm hung and I clearly march to the beat of my own drum, so props to ya, mawma!",
    allowed: false,
  },
  {
    id: 74,
    name: "Trinity K Bonet",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/trinity-k-bonet.jpg",
    quote: "\"\"",
    allowed: false,
  },
  {
    id: 75,
    name: "BenDeLaCreme",
    winner: false,
    missCongeniality: true,
    image_url: "http://www.nokeynoshade.party/images/benda-la-creme.jpg",
    quote: "Excuse me, we originated the language",
    allowed: false,
  },
  {
    id: 76,
    name: "Joslyn Fox",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/josyln-fox.jpg",
    quote: "I'm kinda like the black horse of the compition.",
    allowed: false,
  },
  {
    id: 77,
    name: "Darienne Lake",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/darienne-lake.jpg",
    quote: "Well, I am two tons of fun.",
    allowed: false,
  },
  {
    id: 78,
    name: "Courtney Act",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/courtney-act.jpg",
    quote: "Sewing is not my forte but... everything else is.",
    allowed: false,
  },
  {
    id: 79,
    name: "Adore Delano",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/adore-delano.jpg",
    quote: "So Laganja has ideas and I mean I was raised right so all I can do is nervous laugh at them because I don't know how to respond to them. I'm not going to say 'No bitch that's an awful idea and mine is better' but that was the truth of this situation.",
    allowed: false,
  },
  {
    id: 80,
    name: "Bianca Del Rio",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/bianca-del-rio.png",
    quote: "I will show you versatility when Santino wins a sewing competition and Visage wears a fucking turtle neck!",
    allowed: true,
  },
  {
    id: 81,
    name: "Tempest DuJour",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/tempest-dujour.jpg",
    quote: "You don't stop dreaming at a certain age!",
    allowed: false,
  },
  {
    id: 82,
    name: "Sasha Belle",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/sasha-belle.jpg",
    quote: "I wore a lobster dress",
    allowed: false,
  },
  {
    id: 83,
    name: "Jasmine Masters",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jasmine-masters.jpg",
    quote: "You gotta pop dem corns so the kids can eat!",
    allowed: false,
  },
  {
    id: 84,
    name: "Mrs. Kasha Davis",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/mrs-kasha-davis.jpg",
    quote: "There's always time for a cocktail",
    allowed: false,
  },
  {
    id: 85,
    name: "Kandy Ho",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kandy-ho.jpg",
    quote: "This is right on my alley!",
    allowed: false,
  },
  {
    id: 86,
    name: "Max",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/max.jpg",
    quote: "It's a Drag competition; if you're not optimistic and having fun then you're not doing it right!",
    allowed: false,
  },
  {
    id: 87,
    name: "Jaidynn Diore Fierce",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jaidynn-diore-fierce.jpg",
    quote: "My name is Jaidynn Diore Fierce. Capital F-I-E-R-C-E because I am what? Fierce",
    allowed: false,
  },
  {
    id: 88,
    name: "Miss Fame",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/miss-fame.jpg",
    quote: "I love chickens! They are my favorite animal.",
    allowed: false,
  },
  {
    id: 89,
    name: "Trixie Mattel",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/trixie-mattel.png",
    quote: "Alright, Public school, calm down.",
    allowed: true,
  },
  {
    id: 90,
    name: "Katya Zamolodchikova",
    winner: false,
    missCongeniality: true,
    image_url: "/img/queens/katya.png",
    quote: "I’m trying to just serve sexy and confident whore slutty cougar on the prowl. 3rd rate Faith Hill impersonator. 80% sexy, 20% disgusting.",
    allowed: true,
  },
  {
    id: 91,
    name: "Kennedy Davenport",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kennedy-davenport.jpg",
    quote: "Love is understanding, unconditional. Love is accepting, and never fails.",
    allowed: false,
  },
  {
    id: 92,
    name: "Pearl",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/pearl.jpg",
    quote: "Very flazéda.",
    allowed: false,
  },
  {
    id: 93,
    name: "Ginger Minj",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/ginger-minj.jpg",
    quote: "I'm about to flood my basement.",
    allowed: false,
  },
  {
    id: 94,
    name: "Violet Chachki",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/violet-chachki.jpg",
    quote: "Pain is beauty and I'm the prettiest.",
    allowed: false,
  },
  {
    id: 95,
    name: "Laila McQueen",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/laila-mcqueen.jpg",
    quote: "Alright ladies, lets turn up the juice and see what shakes loose!",
    allowed: false,
  },
  {
    id: 96,
    name: "Dax ExclamationPoint",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/dax-exclamation-point.jpg",
    quote: "What's up, nerds?",
    allowed: false,
  },
  {
    id: 97,
    name: "Cynthia Lee Fontaine",
    winner: false,
    missCongeniality: true,
    image_url: "https://vignette4.wikia.nocookie.net/logosrupaulsdragrace/images/3/34/CynthiaS9.jpg",
    quote: "How you doin' mis amores? Do you want to see my cucu?",
    allowed: false,
  },
  {
    id: 98,
    name: "Naysha Lopez",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/naysha-lopez.jpg",
    quote: "Hola! The beauty is here!",
    allowed: false,
  },
  {
    id: 99,
    name: "Acid Betty",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/acid-betty.jpg",
    quote: "No need to adjust your TV sets. This acid trip is all real!",
    allowed: false,
  },
  {
    id: 100,
    name: "Robbie Turner",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/robbie-turner.jpg",
    quote: "I'm gonna tell you a story about flats. No.",
    allowed: false,
  },
  {
    id: 101,
    name: "Thorgy Thor",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/thorgy-thor.jpg",
    quote: "Now take this orange shit of my body!",
    allowed: false,
  },
  {
    id: 102,
    name: "Derrick Barry",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/derrick-barry.jpg",
    quote: "Who else has been carb free for two weeks?",
    allowed: false,
  },
  {
    id: 103,
    name: "Chi Chi DeVayne",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/chi-chi-devayne.jpg",
    quote: "I'm a cheap queen.",
    allowed: false,
  },
  {
    id: 104,
    name: "Naomi Smalls",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/naomi-smalls.png",
    quote: "Check your lipstick before you come for me.",
    allowed: true,
  },
  {
    id: 105,
    name: "Kim Chi",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kim-chi.jpg",
    quote: "Donut come for me!",
    allowed: false,
  },
  {
    id: 106,
    name: "Bob the Drag Queen",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/bob-the-drag-queen.png",
    quote: "Purse first! Purse first! Walk into the room purse first! Clack!",
    allowed: true,
  },
  {
    id: 107,
    name: "Jamyes Mansfield",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/jaymes-mansfield.jpg",
    quote: "These are my summer diamonds. Some are diamonds and some are not.",
    allowed: false,
  },
  {
    id: 108,
    name: "Kimora Blac",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kimora-blac.jpg",
    quote: "imora Blac is everyone's sexual preference.",
    allowed: false,
  },
  {
    id: 109,
    name: "Charlie Hides",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/charlie-hides.jpg",
    quote: "I'm not a lip sync performer, 99% of the acts in London sing live. So I just need Ru to say it: Sashay away",
    allowed: false,
  },
  {
    id: 110,
    name: "Eureka O'Hara",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/eureka-ohara.jpg",
    quote: "Gag. C'mon big girl!",
    allowed: false,
  },
  {
    id: 111,
    name: "Aja",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/aja.jpg",
    quote: "You’re perfect, you’re beautiful, you look like Linda Evangelista, you’re a model! Everything about you is perfect! Did you stone those tights?",
    allowed: false,
  },
  {
    id: 112,
    name: "Farrah Moan",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/farrah-moan.jpg",
    quote: "Someone told me for the first time in my life that I wear too much highlighter!",
    allowed: false,
  },
  {
    id: 113,
    name: "Valentina",
    winner: false,
    missCongeniality: true,
    image_url: "/img/queens/valentina.png",
    quote: "I'm glad these girls see me as a threat.",
    allowed: true,
  },
  {
    id: 114,
    name: "Nina Bo'Nina Brown",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/nina-bonina-brown.jpg",
    quote: "I'm Nina, Bo'nina, Banana, Fofana, Osama, Bin Laden Brown! Boom boom boom boom",
    allowed: false,
  },
  {
    id: 118,
    name: "Alexis Michelle",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/alexis-michelle.jpg",
    quote: "I'm a good girl, I am.",
    allowed: false,
  },
  {
    id: 119,
    name: "Peppermint",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/peppermint.png",
    quote: "She's gonna have to pry this from my cold, dead, manicured hands.",
    allowed: true,
  },
  {
    id: 123,
    name: "Trinity Taylor",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/trinity-taylor.png",
    quote: "I have 50 units of botox in my face, I don't know what y'all want from me.",
    allowed: true,
  },
  {
    id: 124,
    name: "Sasha Velour",
    winner: true,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/sasha-velour.jpg",
    quote: "Don't joke about that.",
    allowed: false,
  },
  {
    id: 125,
    name: "Shea Coulee",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/shea-coulee.png",
    quote: "Trinity, when's your birthday? I'ma mail you some edges.",
    allowed: true,
  },
  {
    id: 126,
    name: "Aquaria",
    winner: true,
    missCongeniality: false,
    image_url: "/img/queens/aquaria.png",
    quote: "I don't define drag, drag is defined by me.",
    allowed: true,
  },
  {
    id: 127,
    name: "Miz Cracker",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/miz-cracker.png",
    quote: "I'm a jewish Barbie on bath salts.",
    allowed: true,
  },
  {
    id: 128,
    name: "Yuhua Hamasaki",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/yuhua_hamasaki.jpg",
    quote: "Hi, gay people!",
    allowed: false,
  },
  {
    id: 129,
    name: "Dusty Rae Bottoms",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/dusty_rae_bottoms.jpg",
    quote: "Never loved ya!",
    allowed: false,
  },
  {
    id: 130,
    name: "Monet X Change",
    winner: false,
    missCongeniality: true,
    image_url: "/img/queens/monet-x-change.png",
    quote: "Oh, don't mind me. I just came here to sweep up the competition, girl.",
    allowed: true,
  },
  {
    id: 131,
    name: "Asia O'Hara",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/asia_ohara.jpg",
    quote: "Well God, Texas is back in the house.",
    allowed: false,
  },
  {
    id: 132,
    name: "Blair St. Clair",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/blair_st_clair.jpg",
    quote: "I'm Blair St. Clair. I just got here this morning.",
    allowed: false,
  },
  {
    id: 133,
    name: "Kalorie Karbdashian Williams",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kalorie_k_williams.jpg",
    quote: "Hope you're ready for your all-carb, high-calorie diet.",
    allowed: false,
  },
  {
    id: 134,
    name: "Kameron Michaels",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/kameron_michaels.jpg",
    quote: "Well I auditioned for Pit Crew, but this is gonna be way more fun.",
    allowed: false,
  },
  {
    id: 135,
    name: "Mayhem Miller",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/mayhem_miller.jpg",
    quote: "Guess who finally decided to crash the party.",
    allowed: false,
  },
  {
    id: 136,
    name: "Monique Heart",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/monique-heart.jpg",
    quote: "Cat kitty cat cat cat cat. Cat kitty cat cat cat. Bringing you the heart of Season 10, honey. Monique Heart. The face.",
    allowed: false,
  },
  {
    id: 137,
    name: "Vanessa Vanjie Mateo",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/vanessa-vanjie-mateo.png",
    quote: "Miss Vaaaanjie... Miss Vaaaanjie... Miss... Vaaanjie",
    allowed: true,
  },
  {
    id: 138,
    name: "The Vixen",
    winner: false,
    missCongeniality: false,
    image_url: "http://www.nokeynoshade.party/images/the_vixen.jpg",
    quote: "I'm just here to fight!",
    allowed: false,
  },
  {
    id: 139,
    name: "Soju",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/soju.jpg",
    quote: "I have a cyst, I'm currently oozing.",
    allowed: false,
  },
  {
    id: 140,
    name: "Kahanna Montrese",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/kahanna-montrese.jpg",
    quote: "Rhinestones can be for pretty girls.",
    allowed: false,
  },
  {
    id: 141,
    name: "Honey Davenport",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/honey-davenport.jpg",
    quote: "This honey is raw and unfiltered.",
    allowed: false,
  },
  {
    id: 142,
    name: "Mercedes Iman Diamond",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/mercedes-iman-diamond.jpg",
    quote: "Opulence! You, own, everything",
    allowed: false,
  },
  {
    id: 143,
    name: "Ariel Versace",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/ariel-versace.jpg",
    quote: "Nyass",
    allowed: false,
  },
  {
    id: 144,
    name: "Scarlet Envy",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/scarlet-envy.jpg",
    quote: "My drag is about embracing the beauty that you were blessed with... and I happened to be very blessed.",
    allowed: false,
  },
  {
    id: 145,
    name: "Ra'Jah O'Hara",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/rajah-ohara.jpg",
    quote: "I mean I'm gagging from, like, the smell.",
    allowed: false,
  },
  {
    id: 146,
    name: "Plastique Tiara",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/plastique-tiara.jpg",
    quote: "I like to describe my drag as 3 P's: polished, persistent and pussy.",
    allowed: false,
  },
  {
    id: 147,
    name: "Shuga Cain",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/shuga-cain.jpg",
    quote: "Do I have to really say 40?",
    allowed: false,
  },
  {
    id: 148,
    name: "Nina West",
    winner: false,
    missCongeniality: true,
    image_url: "/img/queens/nina-west.png",
    quote: "Welcome to Season 11. It looks like things just got... elevengendary.",
    allowed: true,
  },
  {
    id: 149,
    name: "Silky Nutmeg Ganache",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/silky-nutmeg-ganache.jpg",
    quote: "Big Silk got the good ol' milk!",
    allowed: false,
  },
  {
    id: 150,
    name: "A'keria Chanel Davenport",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/akeria-chanel-davenport.jpg",
    quote: "Oh yes, it's me: Miss A'keria D-A-V-E-N-P-O-R, and as you can see, I'm the motherfucking T.",
    allowed: false,
  },
  {
    id: 151,
    name: "Brooke Lynn Hytes",
    winner: false,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/brooke-lynn-hytes.jpg",
    quote: "Never fear, the queen of the North is here.",
    allowed: false,
  },
  {
    id: 152,
    name: "Yvie Oddly",
    winner: true,
    missCongeniality: false,
    image_url: "https://www.nokeynoshade.party/images/yvie-oddly.jpg",
    quote: "Move over ladies, this race just took an odd turn.",
    allowed: false,
  },
  {
    id: 155,
    name: "Aiden Zhane",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/c/cd/AidenZhane.jpg/revision/latest/scale-to-width-down/1000?cb=20200124021811",
    quote: "boo",
    allowed: false,
  },
  {
    id: 156,
    name: "Brita Filter",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/7/7f/Brita.jpg",
    quote: "My name is Brita and I am THE QUEEN of New York City.",
    allowed: false,
  },
  {
    id: 157,
    name: "Crystal Methyd",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/f/ff/CrystalMethyd.jpg",
    quote: "I'm Crystal Methyd and I'm a little devil that likes to raise hell in the Bible Belt.",
    allowed: false,
  },
  {
    id: 158,
    name: "Dahlia Sinn",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/1/16/DahliaSin.jpg",
    quote: "I'm Dahlia Sin and I'm here to take your man.",
    allowed: false,
  },
  {
    id: 159,
    name: "Gigi Goode",
    winner: false,
    missCongeniality: false,
    image_url: "/img/queens/gigi-goode.png",
    quote: "My name is Gigi Goode and I'm not just good, I'm incredible.",
    allowed: true,
  },
  {
    id: 160,
    name: "Heidi N Closet",
    winner: false,
    missCongeniality: true,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/8/85/HeidiNCloset.jpg",
    quote: "Heidi to the closet is her name, being soft and supple is her game!",
    allowed: false,
  },
  {
    id: 161,
    name: "Jackie Cox",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/5/52/JackieCox.jpg",
    quote: "Roll call...I'm Jackie! Salaam! The Persian princess of drag has arrived!",
    allowed: false,
  },
  {
    id: 162,
    name: "Jaida Essence Hall",
    winner: true,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/b/b5/JaidaEssenceHall.jpg",
    quote: "LOOK OVER THERE!",
    allowed: false,
  },
  {
    id: 163,
    name: "Jan",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/8/82/Jan.jpg",
    quote: "Oh, it's just Jan!",
    allowed: false,
  },
  {
    id: 164,
    name: "Nicky Doll",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/6/66/NickyDoll.jpg",
    quote: "Well, that was a long flight.",
    allowed: false,
  },
  {
    id: 165,
    name: "Rock M. Sakura",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/c/cf/RockMSakura.jpg",
    quote: "All tuck and no play makes Rock M. a crazy bitch!",
    allowed: false,
  },
  {
    id: 166,
    name: "Widow Von'Du",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/2/24/WidowVonDu.jpg",
    quote: "Relax your throat bitches, 'cause you're gagging.",
    allowed: false,
  },
  {
    id: 167,
    name: "Sherry Pie",
    winner: false,
    missCongeniality: false,
    image_url: "https://static.wikia.nocookie.net/logosrupaulsdragrace/images/b/b1/SherryPie.jpg",
    quote: "",
    allowed: false,
  },
  {
    id: 168,
    name: "Denali",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-denali.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  169,
    name: "Elliott with 2 Ts",
    winner:  false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-elliott.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  170,
    name: "Gottmik",
    winner:  false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-gottmik.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  171,
    name: "Kandy Muse",
    winner: false,
    missCongeniality:  false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-kandy-muse.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  172,
    name: "Olivia Lux",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-olivia-lux.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  173,
    name: "Rosé",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-rose.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  174,
    name: "Symone",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-symone.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  175,
    name: "Tina Burner",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-tina-burner.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  176,
    name: "Utica Queen",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-utica-queen.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  177,
    name: "LaLa Ri",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-lala-ri.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  178,
    name: "Tamisha Iman",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-tamisha-iman.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  179,
    name: "Joey Jay",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-joey-jay.jpg?w=620",
    quote: "",
    allowed: false,
  },
  {
    id:  180,
    name: "Kahmora Hall",
    winner: false,
    missCongeniality: false,
    image_url: "https://tvline.com/wp-content/uploads/2020/12/drag-race-season-13-cast-kahmora-hall.jpg?w=620",
    quote: "",
    allowed: false,
  }
].filter(({allowed}: {allowed: boolean}) => allowed);

export default queens;
